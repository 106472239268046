import React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import '../../components/Breadcrumb.css'
import styled from "styled-components";
import media from "styled-media-query";

import Layout from "../../components/Layout";
import Button from "../../components/Button";
import Facilities from "../../components/Facilities";

import MainImage1 from "../../img/kiraku-2.jpg";
import MainImage2 from "../../img/kiraku-3.jpg";
import BgImage1 from "../../img/news-bg.png";

const KirakuPage = ({ pageContext }) => {
  const { breadcrumb: { crumbs }} = pageContext;

  return (
    <Layout>
      {/*
      パンくず
      メインビジュアル
      タイトル
      2カラム紹介
      2カラムボタン
      詳細施設案内
      Googleマップ埋め込み
      共通の施設案内
      パンくず
      */}
      <Body>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="シェアハウス気楽荘"  />
      <ImageBig src={MainImage1}></ImageBig>
      <MidashiBg>
        <Komidashi>岡山市中心部での短期滞在・お試し暮らし。<br />
          移住のための拠点づくりに<br /><br />
          シェアハウス</Komidashi>
        <Midashi>気楽荘</Midashi>
      </MidashiBg>
      <p>
        NAWATE PROJECTが窓口となって、岡山での滞在をお手伝いするシェアハウスです。<br />
        木造で築60年、元旅館の物件を少し改修しています。<br />
        奉還町商店街とJR岡山駅の間にあり、駅から徒歩3分と好立地にあります。<br />
        ゲストハウスとりいくぐるや姉妹店のラウンジ・カドまで徒歩10分。入居のサポートや移住後の相談も。
      </p>
      <Intro2ColumnDiv>
          <Column>
              <ImageSmall src={MainImage2}></ImageSmall>
          </Column>
          <Column>
            <p><strong>家賃</strong><br />
                月￥34,000 + 水道光熱費・通信費 月￥10,000程度<br />
                <br />
                ・空きがあればすぐに入居できます<br />
                ・家具が揃っています<br />
                ・JR岡山駅まで徒歩3分<br />
                ・入居後も近くでスタッフがサポート<br />
                ・ご家族や友人がきた時はとりいくぐるに宿泊できます<br />
                <br />
                <strong>共用部分</strong> <br />
                リビング、TV、Wi-Fi、キッチン、冷蔵庫、調理器具、食器類、シャワー、ヘアドライヤー、トイレ、洗面台、洗濯機、物干し (2F ベランダ )、掃除機<br />
                ※共用スペースはNAWATE PROJECTのスタッフが月に数回清掃を行います<br />
                <br />
                <strong>個室</strong><br />
                各部屋 4.5 畳に押入れ付き。全6部屋。現在の空き部屋は1室ございます。
            </p>
                <br />
                <br />
          </Column>
      </Intro2ColumnDiv>
      <Button2ColumnDiv>
          <Button color="#666666" color2="#ffffff" words="シェアハウス気楽荘を見学する" to="/form/kiraku"></Button>
      </Button2ColumnDiv>
      </Body>
      <Facilities />
      <FooterDiv>
        <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="Guesthouse&Lounge とりいくぐる"  />
      </FooterDiv>
    </Layout>
  );
}

export default KirakuPage;

const Body = styled.div`
  margin: 0 5vw 3em 5vw;
  
`;

const MidashiBg = styled.div`
  width: 100%;
  background-image: url(${BgImage1});
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
  text-align: center;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 11em;
    margin: 0 0 2em 0;
    padding: 2em 15vw 0 15vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 15em;
    margin: 0 0 5em 0;
    padding: 5em 15vw 0 15vw;
  `}
`;

const Intro2ColumnDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
    margin: 5vh 0 0 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 12vh 0 0 0;
  `}
`;

const Button2ColumnDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 0 auto 0 auto;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 5vh auto 0 auto;
  `}
`;

const FooterDiv = styled.div`
  margin: 3vh 5vw 3vh 5vw;
`;

const Column = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
    margin: 0 0 10vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 40vw;
  `}
`;

const ImageBig = styled.img`
  width: 100%;
  border-radius: 10px;
  margin: 0 0 3vh 0;
  object-fit: cover;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 30vh;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 80vh;
  `}
`;

const ImageSmall = styled.img`
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 30vh;
    margin: 0 0 3vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 50vh;
    margin: 0 0 10vh 0;
  `}
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 18pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 28pt;
  `}
`;

const Komidashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 10pt;
    margin: 0 0 1vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 18pt;
    margin: 0 0 5vh 0;
  `}
`;
